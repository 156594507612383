import React, {Fragment, useState} from "react";
import Preview from "../Details/Preview";
import Button from "components/Common/Button";
import Spinner from "components/Common/Spinner";
import {toast} from "react-toastify";
import {
  useCreateLandingMutation,
  useUploadImageMutation,
} from "store/api/landingsApi";
import FormCMS from "../Details/FormCMS";
import Search from "assets/Search";
import {useLazyGetIndividualGrandPrizeByMerchantIDQuery} from "store/api/itemApi";
import {GrandPrizeI} from "store/api/itemTypes";
import {useLazyGetMerchantByIDQuery} from "store/api/merchantsApi";
import {MerchantI} from "page/Merchants/Profile/Details";
import {Switch} from "@headlessui/react";

function LandingCreate() {
  const [createLandingPage] = useCreateLandingMutation();
  const [uploadImage] = useUploadImageMutation();

  const [
    getGrandPrizeInfo,
    {data: grandPrizeInfo, isLoading: isLoadingGrandPrizeInfo},
  ] = useLazyGetIndividualGrandPrizeByMerchantIDQuery() as unknown as [
    (arg: any) => Promise<any>,
    {data: GrandPrizeI | undefined; isLoading: boolean}
  ];

  const [getMerchantByID, {data: dataMerchantByID}] =
    useLazyGetMerchantByIDQuery();

  const [formData, setFormData] = useState({
    title: "To Register",
    buttonText: "Submit",
    form: [],
  });
  const [sendForm, setSendForm] = useState(false);
  const [landingId, setLandingId] = useState("");
  const [gpInfo, setGPInfo] = useState({
    merchantId: "",
    grandPrizeId: "",
  });

  const [landingEnv, setLandingEnv] = useState("dev");

  const [dataLanding, setDataLanding] = useState({
    page: {
      top_content: {
        structure: {
          logo: "",
          image: "",
          title: "",
          description: "",
          expiredAt: "",
          eventType: "",
          backgroundExpiredAt: "",
          formInfo: null,
        },
        style: {
          background: "#f9f7f4",
          color: "",
          expiredAtBackground: "#f8a65a",
          formColor: "#007bff",
        },
      },
      mid_content: {
        structure: {
          title: "",
          description: "",
          image: "",
        },
        style: {
          background: "",
          color: "",
        },
      },
      bottom_content: {
        structure: {
          title: "Terms and Conditions",
          description: "",
          link: {
            label: "",
            url: "",
          },
        },
        style: {
          background: "",
          color: "",
        },
      },
    },
  });

  const handleChange = (path: string[], value: any) => {
    setDataLanding((prev) => {
      const updated = {...prev};
      let obj: any = updated;
      path.forEach((key, index) => {
        if (index === path.length - 1) {
          obj[key] = value;
        } else {
          obj = obj[key];
        }
      });
      return updated;
    });
  };

  const handleCreateLanding = async () => {
    const landingPage = {
      ...dataLanding,
      environment: landingEnv,
      page: {
        ...dataLanding.page,
        top_content: {
          ...dataLanding.page.top_content,
          structure: {
            ...dataLanding.page.top_content.structure,
            formInfo: formData,
          },
        },
      },
    };

    await toast.promise(
      createLandingPage({
        page: landingId,
        data: landingPage,
      }),
      {
        pending: "Creating Landing Page...",
        success: "Landing Page Created",
        error: "Error while creating Landing Page",
      }
    );
  };

  const getGrandPrize = async () => {
    if (gpInfo.merchantId && gpInfo.grandPrizeId) {
      const data = await getGrandPrizeInfo({
        idMerchant: gpInfo.merchantId,
        idItem: gpInfo.grandPrizeId,
      });

      const merchantData = await getMerchantByID(gpInfo.merchantId);

      if (data.isError) {
        toast.error("Error while fetching Grand Prize Info");
        return;
      }

      const merchant = merchantData?.data as MerchantI;
      const landing = data?.data as GrandPrizeI;

      console.log("merchant", merchant);

      // Get the COVER_PHOTO image from the images array
      const cover_photo = landing.medias.find(
        (media) => media.type === "COVER_PHOTO"
      );

      // Get PROFILE image from the images array merchant
      const profile_image = merchant?.images.find(
        (image: any) => image.type === "PROFILE"
      );

      setDataLanding((prev) => {
        return {
          ...prev,
          page: {
            ...prev.page,
            top_content: {
              ...prev.page.top_content,
              structure: {
                ...prev.page.top_content.structure,
                title: landing?.name,
                description: landing?.description,
                expiredAt: landing?.endDate,
                logo: profile_image?.url || "",
                image: cover_photo?.url || "",
              },
            },
            bottom_content: {
              ...prev.page.bottom_content,
              structure: {
                ...prev.page.bottom_content.structure,
                description: landing?.terms,
              },
            },
          },
        };
      });
    }
  };

  const handleImageUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string[]
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Check file size (3MB = 3 * 1024 * 1024 bytes)
    if (file.size > 3 * 1024 * 1024) {
      toast.error("Image size must be less than 3MB");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = (await uploadImage(formData)) as
        | {
            data: {
              fileUrl: string;
            };
          }
        | {
            data: {
              error: {
                message: string;
              };
            };
          };

      if ("data" in response && "fileUrl" in response.data) {
        handleChange(field, response?.data?.fileUrl);
        toast.success("Image uploaded successfully");
      } else {
        console.error("Upload response:", response);
        toast.error("Error uploading image");
      }
    } catch (error: any) {
      console.error("Upload error:", error);
      toast.error(error.data?.message || "Error uploading image");
    }
  };

  console.log("grandPrizeInfo", grandPrizeInfo);

  return (
    <div className="bg-gray-100 min-h-screen ">
      <div className="flex justify-end">
        <Button
          classes="mr-16"
          variant={"add"}
          onClick={() => handleCreateLanding()}
        >
          {sendForm ? <Spinner classes="w-5 h-5 border-b-gray-500" /> : "+"}
          <p className="ml-2">Create Landing Page</p>
        </Button>
      </div>
      <div className="flex gap-8 pt-6">
        {/* CMS Editor */}
        <div className="w-2/5 bg-white shadow-md rounded-lg p-6">
          {/* New input to put the ID of the landing */}
          <div className="flex justify-between mb-4">
            <h2 className="text-xl font-bold mb-4 text-gray-700">CMS Editor</h2>
            <div className="flex gap-2 text-sm">
              <Switch
                defaultChecked={false}
                checked={landingEnv === "prod"}
                as={Fragment}
              >
                {({checked}) => (
                  <>
                    <p className="text-default-500 select-none mt-2">
                      Environment:{" "}
                      <span className="font-medium">
                        {landingEnv === "prod" ? "prod" : "dev"}
                      </span>
                    </p>
                    <button
                      className={`${
                        landingEnv === "prod"
                          ? "bg-primary-purple"
                          : "bg-gray-200"
                      } relative inline-flex h-7 w-12 mt-1 items-center rounded-full`}
                      onClick={() => {
                        setLandingEnv(landingEnv === "prod" ? "dev" : "prod");
                      }}
                    >
                      <span
                        className={`${
                          landingEnv === "prod"
                            ? "translate-x-6"
                            : "translate-x-1"
                        } inline-block h-5 w-5 transform rounded-full bg-white transition`}
                      />
                    </button>
                  </>
                )}
              </Switch>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Identifier (Name for the landing page){" "}
              <label className="text-red-600 mr-2">*</label>
            </label>
            <input
              type="text"
              required
              value={landingId}
              onChange={(e) => setLandingId(e.target.value)}
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mt-4">
              Import Grand Prize Information
            </label>
            <div className="flex justify-between">
              <div className="flex gap-2">
                <input
                  type="text"
                  required
                  value={gpInfo.merchantId}
                  placeholder="Enter Merchant ID "
                  onChange={(e) =>
                    setGPInfo({
                      ...gpInfo,
                      merchantId: e.target.value,
                    })
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <input
                  type="text"
                  required
                  value={gpInfo.grandPrizeId}
                  placeholder="Enter Grand Prize ID "
                  onChange={(e) =>
                    setGPInfo({
                      ...gpInfo,
                      grandPrizeId: e.target.value,
                    })
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <Button
                classes="mt-1 border-solid border-black rounded-md bg-gray-100 hover:bg-gray-200"
                onClick={() => getGrandPrize()}
              >
                {isLoadingGrandPrizeInfo ? (
                  <Spinner classes="border-l-white border-r-white border-t-white w-5 h-5" />
                ) : (
                  <Search />
                )}
              </Button>
            </div>
          </div>
          {/* Top Content Editor */}
          <div className="mb-8 border-r-4 border-red-400 pr-4 mt-5">
            <h3 className="text-xl font-semibold mb-4 text-gray-700 underline">
              Top Content
            </h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Logo URL
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    value={dataLanding?.page?.top_content?.structure?.logo}
                    onChange={(e) =>
                      handleChange(
                        ["page", "top_content", "structure", "logo"],
                        e.target.value
                      )
                    }
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <label className="cursor-pointer">
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) =>
                        handleImageUpload(e, [
                          "page",
                          "top_content",
                          "structure",
                          "logo",
                        ])
                      }
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-gray-500 hover:text-gray-700"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                      />
                    </svg>
                  </label>
                </div>
                <div className="flex gap-2 mt-2">
                  {
                    // Add the thumnail preview of the rest of the images in the images array of the merchant
                    dataMerchantByID?.images?.map((image: any) => {
                      return (
                        <img
                          src={image.url}
                          alt="Top Content Logo"
                          className="w-auto h-8 object-contain cursor-pointer"
                          onClick={() =>
                            handleChange(
                              ["page", "top_content", "structure", "logo"],
                              image.url
                            )
                          }
                        />
                      );
                    })
                  }
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Image URL
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    value={dataLanding.page.top_content.structure.image}
                    onChange={(e) =>
                      handleChange(
                        ["page", "top_content", "structure", "image"],
                        e.target.value
                      )
                    }
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <label className="cursor-pointer">
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) =>
                        handleImageUpload(e, [
                          "page",
                          "top_content",
                          "structure",
                          "image",
                        ])
                      }
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-gray-500 hover:text-gray-700"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                      />
                    </svg>
                  </label>
                </div>
                <div className="flex gap-2 mt-2">
                  {
                    // Add the thumnail preview of the rest of the images in the medias array
                    grandPrizeInfo?.medias?.map((media: any) => {
                      return (
                        <img
                          src={media.url}
                          alt="Top Content"
                          className="w-auto h-8 object-contain cursor-pointer"
                          onClick={() =>
                            handleChange(
                              ["page", "top_content", "structure", "image"],
                              media.url
                            )
                          }
                        />
                      );
                    })
                  }
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <input
                  type="text"
                  value={dataLanding.page.top_content.structure.title}
                  onChange={(e) =>
                    handleChange(
                      ["page", "top_content", "structure", "title"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  value={dataLanding.page.top_content.structure.description}
                  onChange={(e) =>
                    handleChange(
                      ["page", "top_content", "structure", "description"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Expired At
                </label>
                <input
                  type="datetime-local"
                  value={
                    dataLanding?.page?.top_content?.structure?.expiredAt
                      ? new Date(
                          dataLanding?.page?.top_content?.structure?.expiredAt
                        )
                          ?.toISOString()
                          ?.slice(0, -1)
                      : undefined
                  }
                  onChange={(e) =>
                    handleChange(
                      ["page", "top_content", "structure", "expiredAt"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Event Type
                </label>
                <input
                  type="text"
                  value={dataLanding.page.top_content.structure.eventType}
                  onChange={(e) =>
                    handleChange(
                      ["page", "top_content", "structure", "eventType"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="flex gap-4">
                <FormCMS formData={formData} setFormData={setFormData} />
              </div>
              <div className="flex gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Background
                  </label>
                  <input
                    type="color"
                    value={dataLanding.page.top_content.style.background}
                    onChange={(e) =>
                      handleChange(
                        ["page", "top_content", "style", "background"],
                        e.target.value
                      )
                    }
                    className="mt-1 block px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="">
                  {/* Button color */}
                  <label className="block text-sm font-medium text-gray-700">
                    Form Color
                  </label>
                  <input
                    type="color"
                    value={dataLanding.page.top_content.style.formColor}
                    onChange={(e) =>
                      handleChange(
                        ["page", "top_content", "style", "formColor"],
                        e.target.value
                      )
                    }
                    className="mt-1 block px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Expires Color
                  </label>
                  <input
                    type="color"
                    value={
                      dataLanding.page.top_content.style.expiredAtBackground
                    }
                    onChange={(e) =>
                      handleChange(
                        ["page", "top_content", "style", "expiredAtBackground"],
                        e.target.value
                      )
                    }
                    className="mt-1 block px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-8 border-r-4 border-green-400 pr-4">
            <h3 className="text-xl font-semibold mb-4 text-gray-700 underline">
              Middle Content
            </h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <input
                  type="text"
                  value={dataLanding.page.mid_content.structure.title}
                  onChange={(e) =>
                    handleChange(
                      ["page", "mid_content", "structure", "title"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <input
                  type="text"
                  value={dataLanding.page.mid_content.structure.description}
                  onChange={(e) =>
                    handleChange(
                      ["page", "mid_content", "structure", "description"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Image URL
                </label>
                <input
                  type="text"
                  value={dataLanding.page.mid_content.structure.image}
                  onChange={(e) =>
                    handleChange(
                      ["page", "mid_content", "structure", "image"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div className="mb-8 border-r-4 border-blue-400 pr-4">
            <h3 className="text-xl font-semibold mb-4 text-gray-700 underline">
              Bottom Content
            </h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <input
                  type="text"
                  value={dataLanding.page.bottom_content.structure.title}
                  onChange={(e) =>
                    handleChange(
                      ["page", "bottom_content", "structure", "title"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  value={dataLanding.page.bottom_content.structure.description}
                  onChange={(e) =>
                    handleChange(
                      ["page", "bottom_content", "structure", "description"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="flex gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Label
                  </label>
                  <input
                    type="text"
                    value={dataLanding.page.bottom_content.structure.link.label}
                    onChange={(e) =>
                      handleChange(
                        [
                          "page",
                          "bottom_content",
                          "structure",
                          "link",
                          "label",
                        ],
                        e.target.value
                      )
                    }
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Link
                  </label>
                  <input
                    type="text"
                    value={dataLanding.page.bottom_content.structure.link.url}
                    onChange={(e) =>
                      handleChange(
                        ["page", "bottom_content", "structure", "link", "url"],
                        e.target.value
                      )
                    }
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Other Sections */}
          {/* Repeat similar blocks for mid_content and bottom_content */}
        </div>

        {/* Preview Panel */}
        <div className="w-3/5 bg-white shadow-md rounded-lg p-6 h-fit">
          <h2 className="text-2xl font-bold mb-4 text-gray-700">Preview</h2>

          <Preview page={dataLanding.page} formInfo={formData} />
        </div>
      </div>
    </div>
  );
}

export default LandingCreate;
