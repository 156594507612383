import Button from "components/Common/Button";
import DeleteModal from "components/Modal/DeleteModal";
import React, {useState} from "react";
import {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  useDeleteLandingMutation,
  useGetLandingsQuery,
  useLazyGetLandingQuery,
} from "store/api/landingsApi";
import {setOpenModal, setType} from "store/modal/modalSlice";
import {toast} from "react-toastify";
import DuplicateModal from "components/Modal/DuplicateModal";

const convertDate = (date: string) => {
  // Convert ISOString to  5/1/25 (MM/DD/YY)
  const d = new Date(date);
  return `${d.getMonth() + 1}/${d.getDate()}/${d
    .getFullYear()
    .toString()
    .slice(-2)}`;
};

function Landings() {
  const {
    data: dataLandings,
    isLoading: isLoadingLandings,
    isError: isErrorLandings,
    refetch,
    // @ts-ignore
  } = useGetLandingsQuery();

  const [
    getLanding,
    {data: dataLanding, isLoading: isLoadingLanding, isError: isErrorLanding},
  ] = useLazyGetLandingQuery();

  const [deleteLandingPage] = useDeleteLandingMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const modal = useSelector((state: RootState) => state.modal);

  const [currentId, setCurrentId] = useState({
    id: "",
    name: "",
  });

  if (isLoadingLandings) return <div>Loading...</div>;

  if (isErrorLandings)
    return <div>Error loading landings. Please try again.</div>;

  if (!dataLandings || dataLandings.length === 0)
    return <div>No landing pages available.</div>;

  const handleDeleteLanding = async () => {
    console.log("Deleting landing with ID:", currentId.id);
    const toastPromise = toast.promise(deleteLandingPage(currentId.id), {
      pending: "Deleting Landing page",
    });
    await toastPromise
      .then((res: any) => {
        if (res.error) {
          toast.error(
            <div>
              <h2>Error while deleting landing page</h2>
              <p className="text-xs"> {res?.error?.data?.message} </p>
            </div>
          );
        } else {
          toast.success("Landing page Deleted");

          dispatch(setOpenModal(false));
          dispatch(setType(""));
          setCurrentId({id: "", name: ""});
          refetch();
        }
      })
      .catch((err) => {
        toast.error("Error while deleting landing page");
      });
  };

  const handleDuplicateLanding = async () => {
    try {
      const response = await getLanding(currentId.id);
      if (response.error) {
        return {error: true};
      }
      return response;
    } catch (error) {
      console.error("Error fetching landing:", error);
      return {error: true};
    }
  };

  return (
    <div className="flex flex-col gap-8 p-6 bg-gray-100 min-h-screen">
      <div className="flex justify-between">
        <h1 className="text-xl font-bold">Landing Pages</h1>
        <div className="flex justify-end">
          <Button
            classes="mr-16"
            variant={"add"}
            onClick={() => {
              navigate("/landing/create");
            }}
          >
            <p className="ml-2">+ Create Landing Page</p>
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
        {dataLandings.map((landing: any) => {
          const {id, page, data} = landing;

          // Skip rendering if `data` is empty
          if (!data) return null;

          // Extract content sections
          const topContent = data?.page?.top_content?.structure;
          const topStyle = data?.page?.top_content?.style;

          return (
            <div
              key={id}
              className="border shadow-md bg-white hover:shadow-lg transition-shadow"
            >
              <div className="mb-4 bg-[#101827] text-white text-center p-3">
                <h2 className="text-lg font-semibold">
                  {page || "Unknown Page"}
                </h2>
                <div className="flex justify-between px-12 mt-2">
                  <p className="text-sm text-gray-600">
                    <strong>Environment:</strong>{" "}
                    <span className="text-gray-400 font-medium">
                      {data.environment || "N/A"}
                    </span>
                  </p>
                  <p className="text-sm text-gray-600">
                    <strong>ID:</strong>{" "}
                    <span className="text-gray-400 font-medium">
                      {id || "N/A"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="p-4 pt-0 h-[calc(100%-183px)]">
                {/* Render Top Content */}
                {topContent && (
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-col gap-1 text-center">
                      {topContent.logo && (
                        <img
                          src={topContent.logo}
                          alt="Top Content Logo"
                          className="w-auto h-8 object-contain"
                        />
                      )}
                    </div>
                    <div className="flex flex-col gap-1 text-center">
                      {topContent.title && (
                        <p className="font-semibold">{topContent.title}</p>
                      )}
                    </div>
                    <div className="flex flex-col gap-1 text-center">
                      {topContent.image && (
                        <img
                          src={topContent.image}
                          alt="Top Content Logo"
                          className="w-auto h-32 object-contain"
                        />
                      )}
                    </div>
                    <div className="flex mt-3 gap-1 text-center justify-between text-sm">
                      <div
                        className="p-1 border border-transparent rounded-sm font-medium"
                        style={{
                          background: topStyle?.expiredAtBackground || "",
                        }}
                      >
                        Expires{" "}
                        {topContent?.expiredAt &&
                          convertDate(topContent?.expiredAt)}
                      </div>
                      <div className="p-1 border border-[#452C1E] text-[#452C1E] rounded-sm font-medium">
                        {topContent?.eventType}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="">
                <button
                  className="w-full bg-[#fdfdfd75] text-gray-700 text-center p-4 py-2.5 hover:text-white hover:bg-primary-purple font-medium border-y border-gray-200"
                  onClick={() => {
                    window.open(`/landing/${id}`, "_blank");
                  }}
                >
                  Check Landing
                </button>
                <div className="flex">
                  <button
                    className="w-full bg-[rgba(207,237,255,0.52)] text-[#363636] text-center p-4 py-2.5 hover:text-white rounded-bl-md hover:bg-[rgb(83,190,255)]  font-medium"
                    onClick={() => {
                      setCurrentId({
                        id,
                        name: page,
                      });
                      dispatch(setOpenModal(true));
                      dispatch(setType("duplicateLanding"));
                    }}
                  >
                    Duplicate
                  </button>
                  <button
                    className="w-full bg-[#ffbebe75] text-red-700 text-center p-4 py-2.5 rounded-br-md hover:text-white hover:bg-[#bf2727] font-medium"
                    onClick={() => {
                      setCurrentId({
                        id,
                        name: page,
                      });
                      dispatch(setOpenModal(true));
                      dispatch(setType("deleteLanding"));
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {modal.type === "deleteLanding" && (
        <DeleteModal
          elementsChecked={[]}
          setElementsChecked={() => {}}
          dataSource={null}
          matchWidhId="id"
          pendingMsg="Deleting landing..."
          returnValue="name"
          toastMessageId="name"
          nameItem={currentId.name}
          typeOfDelete="landing"
          deleteCallback={handleDeleteLanding}
          cleanFn={() => setCurrentId({id: "", name: ""})}
        />
      )}
      {modal.type === "duplicateLanding" && (
        <DuplicateModal
          landingName={currentId.name}
          promise={() => handleDuplicateLanding()}
          pendingMsg="Duplicating landing..."
          cleanFn={() => setCurrentId({id: "", name: ""})}
        />
      )}
    </div>
  );
}

export default Landings;
