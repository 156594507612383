import {Dispatch, SetStateAction} from "react";
import {toast} from "react-toastify";

// Components
import Modal from "components/Modal";
import Button from "components/Common/Button";

// Redux
import {useDispatch} from "react-redux";
import {setOpenModal, setType} from "store/modal/modalSlice";

// Assets
import Copy from "assets/Copy"; // You'll need to create/import this icon
import {useCreateLandingMutation} from "store/api/landingsApi";
import React from "react";

type DuplicateModalType = {
  landingName: string;
  promise: Function;
  pendingMsg: string;
  cleanFn?: () => void;
};

function DuplicateModal({
  landingName,
  promise,
  pendingMsg,
  cleanFn = () => {},
}: DuplicateModalType) {
  const dispatch = useDispatch();
  const [createLanding] = useCreateLandingMutation();

  const handleSubmit = async () => {
    const toastId = toast.loading(pendingMsg);

    try {
      // Call the promise function (which is handleDuplicateLanding)
      const response = await promise();

      if (response.error) {
        toast.update(toastId, {
          render: "Error fetching landing data for duplication",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        return;
      }

      // RTK Query response structure
      const landingData = response.data;
      if (!landingData) {
        toast.update(toastId, {
          render: "No data found for the selected landing",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        return;
      }

      const {page, data} = landingData;

      console.log("page, data", page, data);

      const result = await createLanding({
        page: `${page} duplicate`,
        data: {
          environment: "dev",
          page: {
            ...data.page,
          },
        },
      }).unwrap();

      if (result) {
        toast.update(toastId, {
          render: `Landing ${landingName} duplicated successfully`,
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });

        setTimeout(() => {
          dispatch(setType(""));
          dispatch(setOpenModal(false));
        }, 1000);
      }
    } catch (error) {
      console.error("Error caught in catch block:", error);
      toast.update(toastId, {
        render: "Error while duplicating landing",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  return (
    <Modal>
      <div className="flex flex-col bg-white text-black rounded w-96 py-4">
        <div className="px-16 mb-4 text-black text-xl font-medium flex flex-col justify-center items-center">
          <div className="rounded-full h-20 w-20 bg-tertiary-purple flex items-center justify-center mt-5">
            <Copy classes="text-primary-purple h-10 w-10" />
          </div>
          <h1 className="text-primary-purple mt-4">Duplicate</h1>
          <p className="text-sm mt-4 text-center">
            Are you sure you want to duplicate landing <b>{landingName}</b>?
          </p>
        </div>
        <hr />
        <div className="mt-8 px-6 flex justify-between items-center">
          <Button
            variant="normal"
            onClick={() => {
              dispatch(setType(""));
              dispatch(setOpenModal(false));
              cleanFn();
            }}
          >
            Cancel
          </Button>
          <Button variant="add" onClick={handleSubmit}>
            Duplicate
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default DuplicateModal;
