// @ts-nocheck
import {useEffect, useRef, useState} from "react";
import {useHotkeys} from "react-hotkeys-hook";

// Hooks
import {useClickOutside} from "hooks/useClickOutside";

// Redux
import {useDispatch} from "react-redux";
import {
  setFilters,
  setFiltersLastSearch,
  setPage,
  setURLSearch,
} from "store/filters/filtersSlice";
import {setLastVisitedPage} from "store/tabs/tabsSlice";

export default function Autocomplete({
  options,
  value,
  onChange,
  classes,
  classesContainer,
  placeholder,
  filters,
  useTags = true,
  callback,
  getIDMerchant,
  getSelectedOption,
  origin,
  onClick,
  hasAutocomplete = true,
}) {
  const ref = useRef();
  const dispatch = useDispatch();
  const [textToSearch, setTextToSearch] = useState("");
  const [listOptions, setListOptions] = useState([]);
  const [listIDs, setListIDs] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [cursor, setCursor] = useState(-1);

  useClickOutside(ref, setShowOptions);
  useHotkeys("alt+s", () => document.getElementById("table-search")?.focus());

  const select = (option) => {
    if (!useTags) {
      if (getSelectedOption && typeof getSelectedOption === "function") {
        getSelectedOption(option);
      }
      if (isNaN(option)) {
        onChange(option.split("~")[0]);

        if (typeof getIDMerchant === "function") {
          getIDMerchant(option.split(":")[1]);
        }
      } else {
        if (typeof getIDMerchant === "function") {
          getIDMerchant(option);
        }
      }
      setShowOptions(false);
      return;
    }

    // Functionality to match with name~id:
    // const expression = `(${option.trimStart()}~id:\\d)\\w+`;
    // const regExp = new RegExp(expression, "gi");

    let elementFound;

    if (
      options.find((optionsEl) =>
        optionsEl
          .toLowerCase()
          .includes(option.trimStart().trimEnd().toLowerCase() + "~id:")
      )
    ) {
      elementFound = options.find((optionsEl) => {
        if (
          optionsEl
            .toLowerCase()
            .includes(option.trimStart().trimEnd().toLowerCase() + "~id:")
        ) {
          return optionsEl;
        }
      });
    } else {
      elementFound = option.trimStart();
    }
    // if (!option) return;

    const updatedOptions = [...listOptions, elementFound];
    let updatedIDs;

    // If :id= exist on option
    if (option.includes("~id:")) {
      updatedIDs = [...listIDs, elementFound?.split("~id:")[1]];
    } else {
      // If is anything else (like zip code or location)
      updatedIDs = [...listIDs, elementFound];
    }

    setShowOptions(false);
    setListOptions(updatedOptions);
    setListIDs(updatedIDs);
    onChange("");

    if (origin === "categories") {
      const string = updatedIDs?.map((el: any) => {
        if (el !== "" && !el.includes(":") && el.length === 5)
          return `zip_code=${el}`;
        else {
          return `city_id=${isNaN(el) ? el?.trim()?.split(":")[1] : el.trim()}`;
        }
      });

      const search = "&" + string.join("&");
      dispatch(setURLSearch(search));
    } else {
      const string = updatedIDs?.map((el: any) => {
        if (el !== "" && !el.includes("~id:")) return `search=${el}`;
        else {
          return `search=${el?.split("~id:")[1]}`;
        }
      });

      const search = "&" + string.join("&");
      dispatch(setURLSearch(search));
    }

    dispatch(setFilters(updatedIDs));
    dispatch(setFiltersLastSearch(updatedOptions));
    dispatch(setPage("&page=1"));
  };

  useEffect(() => {
    if (filters?.lastSearch && filters?.lastSearch?.length > 0) {
      setListOptions(filters?.lastSearch);
    }
  }, [filters?.lastSearch]);

  const selectWithComma = (values: string[]) => {
    // Functionality to match with name~id:
    // Coomi Foods, SebastianSO123
    const match = values.map((searchEl) => {
      // const expression = `(${searchEl.trimStart()}~id:\\d)\\w+`;
      // const regExp = new RegExp(expression, "gi");

      if (isNaN(searchEl.toLowerCase().trim())) {
        if (
          options.find((optionsEl) =>
            optionsEl
              .toLowerCase()
              .includes(searchEl.trimStart().trimEnd().toLowerCase() + "~id:")
          )
        ) {
          return options.find((optionsEl) => {
            if (
              optionsEl
                .toLowerCase()
                .includes(searchEl.trimStart().trimEnd().toLowerCase() + "~id:")
            ) {
              return optionsEl;
            }
          });
        } else {
          return searchEl.trimStart().trimEnd();
        }
      } else {
        return searchEl.trimStart().trimEnd();
      }
    });

    // console.log(match);

    const updatedOptions = [...listOptions, ...match];
    let updatedIDs = [];

    match.forEach((el) => {
      if (el?.includes(":")) {
        updatedIDs = [...updatedIDs, ...listIDs, el?.split("~id:")[1]];
      } else {
        // If is anything else (like zip code or location)
        updatedIDs = [...updatedIDs, ...listIDs, el];
      }
    });

    setShowOptions(false);
    setListOptions(updatedOptions);
    setListIDs(updatedIDs);
    onChange("");

    const string = updatedIDs?.map((el: any) => {
      if (el !== "") return `search=${el}`;
    });
    const search = "&" + string.join("&");

    dispatch(setURLSearch(search));
    dispatch(setFilters(updatedIDs));
    dispatch(setFiltersLastSearch(updatedOptions));
    dispatch(setPage("&page=1"));
  };

  const handleChange = (text) => {
    dispatch(setLastVisitedPage(""));
    setShowOptions(true);
    onChange(text);
    setTextToSearch(text);
    setCursor(-1);
    if (callback) {
      callback(text);
    }
  };

  // Look for every match on the options
  function checkSubstrOmStr(string, substring) {
    var letters = [...string];
    return [...substring].every((x) => {
      var index = letters.indexOf(x);
      if (~index) {
        letters.splice(index, 1);
        return true;
      }
    });
  }

  // Check every option with the value that was clicked
  const filteredOptions = options
    .filter((option) => {
      if (listOptions.includes(option)) {
        return false;
      }
      const lowerOption = option.toLowerCase();
      const lowerValue = value.toLowerCase();

      // Exact match
      if (lowerOption === lowerValue) {
        return true;
      }

      // Partial match
      if (lowerOption.includes(lowerValue)) {
        return true;
      }

      // Word match
      return checkSubstrOmStr(lowerOption, lowerValue);
    })
    .sort((a, b) => {
      const lowerValue = value.toLowerCase();
      const aLower = a.toLowerCase();
      const bLower = b.toLowerCase();

      // Prioritize exact matches
      if (aLower === lowerValue && bLower !== lowerValue) {
        return -1;
      }
      if (aLower !== lowerValue && bLower === lowerValue) {
        return 1;
      }

      // Prioritize partial matches
      if (aLower.includes(lowerValue) && !bLower.includes(lowerValue)) {
        return -1;
      }
      if (!aLower.includes(lowerValue) && bLower.includes(lowerValue)) {
        return 1;
      }

      // Otherwise, keep original order
      return 0;
    });

  const moveCursorDown = () => {
    if (cursor < filteredOptions.length - 1) {
      setCursor((c) => c + 1);
    }
  };

  const moveCursorUp = () => {
    if (cursor > 0) {
      setCursor((c) => c - 1);
    }
  };

  const handleNav = (e) => {
    // console.log(e.key);
    switch (e.key) {
      case "ArrowUp":
        moveCursorUp();
        break;
      case "ArrowDown":
        moveCursorDown();
        break;
      case "Backspace":
        // Remove selected values from the autocomplete
        if (!value && listOptions.length > 0 && listIDs.length > 0) {
          // TODO: Remove autofill match elements
          const updatedName = listOptions.slice(0, listOptions.length - 1);
          const updatedIDs = listIDs.slice(0, listIDs.length - 1);
          setListOptions(updatedName);
          setListIDs(updatedIDs);

          const string = updatedIDs?.map((el: any) => {
            if (el !== "") return `search=${el}`;
          });
          const search = "&" + string.join("&");

          dispatch(setURLSearch(search));
          dispatch(setFiltersLastSearch(updatedName));
          dispatch(setFilters(updatedIDs));
          dispatch(setPage("&page=1"));
        }
        break;
      case "Enter":
        // console.log(e.target.value);
        // if (!e.target.value) return;
        if (cursor >= 0 && cursor < filteredOptions.length) {
          select(filteredOptions[cursor]);
        } else {
          if (textToSearch?.includes(",")) {
            selectWithComma(textToSearch.split(","));
          } else {
            // If it's not a number find a match with the existing values
            // if (isNaN(e.target.value)) {
            //   const match = options.find((optionsEl) =>
            //     optionsEl.toLowerCase().includes(textToSearch.toLowerCase())
            //   );

            //   if (match) {
            //     select(match);
            //   } else {
            //     select(textToSearch);
            //   }
            // } else {
            //   // If the value is a number don't find any match (search by ID)
            // }
            select(textToSearch);
          }
        }
        break;
      default:
        break;
    }
  };

  const handleRemoveElement = (value) => {
    const itemsRemaining = listOptions.filter((item) => item !== value);

    // Get the ids remaining to store on Redux
    const idsRemaining = listOptions
      .filter((item) => item !== value)
      .map((el) => {
        if (el.includes(":")) {
          return el?.split("~id:")[1];
        } else {
          return el;
        }
      });

    if (origin === "categories") {
      // Delete ID on redux, update URLSearch
      const string = idsRemaining?.map((el: any) => {
        if (el !== "" && el.length === 5) return `zip_code=${el}`;
        else return `city_id=${el?.trim()}`;
      });
      const search = `${string.length > 0 ? "&" : ""}${string.join("&")}`;

      dispatch(setURLSearch(search));
    } else {
      // Delete ID on redux, update URLSearch
      const string = idsRemaining?.map((el: any) => {
        if (el !== "") return `search=${el}`;
      });
      const search = `${string.length > 0 ? "&" : ""}${string.join("&")}`;
      dispatch(setURLSearch(search));
    }

    setListIDs(idsRemaining);
    setListOptions(itemsRemaining);
    dispatch(setPage("&page=1"));
    dispatch(setFilters(idsRemaining));
    dispatch(setFiltersLastSearch(itemsRemaining));
    dispatch(setLastVisitedPage(""));
  };

  useEffect(() => {
    const listener = (e) => {
      // const domNode = ReactDOM.findDOMNode(e.target);
      if (!ref.current.contains(e.target)) {
        // setShowOptions(false);
        setCursor(-1);
      }
    };

    // document.dispatchEvent(new Event("click", { bubbles: false }));

    document.addEventListener("click", listener);
    document.addEventListener("focusin", listener);
    return () => {
      document.removeEventListener("click", listener);
      document.removeEventListener("focusin", listener);
    };
  }, []);

  return (
    <div
      className={`${classesContainer} relative flex items-center pl-10 mr-10 text-sm text-gray-400 font-base rounded-r-lg border-2 border-transparent focus:border-primary-purple bg-white`}
      ref={ref}
    >
      <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
        <svg
          className="w-5 h-5 text-gray-500 dark:text-gray-400"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clipRule="evenodd"
          ></path>
        </svg>
      </div>
      <div>
        {useTags ? (
          <div className="flex flex-row flex-wrap gap-2">
            <div className="flex flex-wrap">
              {listOptions.map((option, key) => (
                <div
                  key={key}
                  className="border border-primary rounded-full py-1.5 pl-4 pr-2 text-xs cursor-pointer"
                >
                  {option?.split("~")[0]}
                  <span
                    id={option}
                    onClick={(e) => handleRemoveElement(e.target.id)}
                    className="text-xs text-gray-400 pr-1 ml-2 cursor-pointer"
                  >
                    &times;
                  </span>
                </div>
              ))}
            </div>

            <input
              type="text"
              id="table-search"
              autoComplete="off"
              className={`ml-2 outline-none border-none ${classes}`}
              placeholder={placeholder}
              value={value}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              onKeyDown={handleNav}
            />
          </div>
        ) : (
          <div>
            <input
              type="text"
              id="table-search"
              autoComplete="off"
              className={`ml-2 outline-none border-none ${classes}`}
              placeholder={placeholder}
              value={value}
              onChange={(e) => handleChange(e.target.value)}
              onKeyDown={handleNav}
              onClick={onClick ? () => onClick(origin) : () => {}}
            />
          </div>
        )}
        {hasAutocomplete && (
          <div className="relative">
            <ul
              className={`absolute top-3 w-full bg-white rounded-lg max-h-80 z-20 overflow-y-auto shadow-lg ${
                !showOptions ? "hidden" : ""
              } select-none`}
            >
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option, i, arr) => {
                  let className = "px-4 hover:bg-gray-100 ";

                  if (i === 0) className += "pt-2 pb-1 rounded-t-lg";
                  else if (i === arr.length)
                    className += "pt-1 pb-2 rounded-b-lg";
                  else if (i === 0 && arr.length === 1)
                    className += "py-2 rounded-lg";
                  else className += "py-1";

                  if (cursor === i) {
                    className += " bg-gray-100";
                  }

                  return (
                    <li
                      className={className}
                      key={option}
                      onClick={() => select(option)}
                    >
                      {option?.split("~")[0]}
                    </li>
                  );
                })
              ) : (
                <li className="px-4 py-2 text-gray-500">No results</li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
