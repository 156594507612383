import React, {Fragment, useEffect, useState} from "react";
import Preview from "./Preview";
import {useParams} from "react-router-dom";
import {
  useGetLandingQuery,
  useUpdateLandingMutation,
} from "store/api/landingsApi";
import FormCMS from "./FormCMS";
import Button from "components/Common/Button";
import Spinner from "components/Common/Spinner";
import {toast} from "react-toastify";
import {Switch} from "@headlessui/react";

function LandingDetails() {
  const {id} = useParams();

  const {
    data: dataLandingFetch,
    isLoading: isLoadingLandingFetch,
    isError: isErrorLandingFetch,
    refetch,
  } = useGetLandingQuery(id || "");

  const [updateLandingPage] = useUpdateLandingMutation();

  const [formData, setFormData] = useState({
    title: "",
    buttonText: "",
    form: [],
  });

  const [sendForm, setSendForm] = useState(false);
  const [landingId, setLandingId] = useState("");

  const [dataLanding, setDataLanding] = useState({
    page: {
      top_content: {
        structure: {
          logo: "",
          image: "",
          title: "",
          description: "",
          expiredAt: "",
          eventType: "",
          backgroundExpiredAt: "",
          formInfo: null,
        },
        style: {
          background: "",
          color: "",
          expiredAtBackground: "",
          formColor: "",
        },
      },
      mid_content: {
        structure: {
          title: "",
          description: "",
          image: "",
        },
        style: {
          background: "",
          color: "",
        },
      },
      bottom_content: {
        structure: {
          title: "",
          description: "",
          link: {
            label: "",
            url: "",
          },
        },
        style: {
          background: "",
          color: "",
        },
      },
    },
  });

  const [landingEnv, setLandingEnv] = useState("dev");

  const updateNestedState = (
    path: string[],
    value: any,
    obj: any
  ): typeof obj => {
    if (path.length === 1) {
      return {...obj, [path[0]]: value};
    }
    const [head, ...tail] = path;
    return {
      ...obj,
      [head]: updateNestedState(tail, value, obj[head]),
    };
  };

  const handleChange = (path: string[], value: any) => {
    setDataLanding((prevState) => updateNestedState(path, value, prevState));
  };

  useEffect(() => {
    if (dataLandingFetch && !isLoadingLandingFetch && !isErrorLandingFetch) {
      setDataLanding(dataLandingFetch.data);
      setLandingId(dataLandingFetch?.page);
      setLandingEnv(dataLandingFetch?.data?.environment);
      setFormData({
        title:
          dataLandingFetch.data.page.top_content.structure.formInfo?.title ||
          "",
        buttonText:
          dataLandingFetch.data.page.top_content.structure.formInfo
            ?.buttonText || "",
        form:
          dataLandingFetch.data.page.top_content.structure.formInfo?.form || [],
      });
    }
  }, [dataLandingFetch]);

  const handleUpdateLanding = async () => {
    const landingPage = {
      ...dataLanding,
      page: landingId || "",
      data: {
        environment: landingEnv,
        page: {
          ...dataLanding.page,
          top_content: {
            ...dataLanding.page.top_content,
            structure: {
              ...dataLanding.page.top_content.structure,
              formInfo: formData,
            },
          },
        },
      },
    };

    await toast.promise(
      updateLandingPage({
        id: id || dataLandingFetch.id || "",
        data: landingPage,
      }),
      {
        pending: "Updating Landing Page...",
        success: "Landing Page Updated",
        error: "Error while Updating Landing Page",
      }
    );
  };

  return (
    <div className="gap-8 p-6 bg-gray-100 min-h-screen">
      <div className="flex justify-end">
        <Button
          classes="mr-16"
          variant={"add"}
          onClick={() => handleUpdateLanding()}
        >
          {sendForm ? <Spinner classes="w-5 h-5 border-b-gray-500" /> : "+"}
          <p className="ml-2">Update Landing Page</p>
        </Button>
      </div>
      <div className="flex gap-8 pt-6">
        {/* CMS Editor */}
        <div className="w-2/5 bg-white shadow-md rounded-lg p-6">
          <div className="flex justify-between mb-4">
            <h2 className="text-xl font-bold mb-4 text-gray-700">CMS Editor</h2>
            <div className="flex gap-2 text-sm">
              <Switch
                defaultChecked={false}
                checked={landingEnv === "prod"}
                as={Fragment}
              >
                {({checked}) => (
                  <>
                    <p className="text-default-500 select-none mt-2">
                      Environment:{" "}
                      <span className="font-medium">
                        {landingEnv === "prod" ? "prod" : "dev"}
                      </span>
                    </p>
                    <button
                      className={`${
                        landingEnv === "prod"
                          ? "bg-primary-purple"
                          : "bg-gray-200"
                      } relative inline-flex h-7 w-12 mt-1 items-center rounded-full`}
                      onClick={() => {
                        setLandingEnv(landingEnv === "prod" ? "dev" : "prod");
                      }}
                    >
                      <span
                        className={`${
                          landingEnv === "prod"
                            ? "translate-x-6"
                            : "translate-x-1"
                        } inline-block h-5 w-5 transform rounded-full bg-white transition`}
                      />
                    </button>
                  </>
                )}
              </Switch>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Identifier (Name for the landing page){" "}
              <label className="text-red-600 mr-2">*</label>
            </label>
            <input
              type="text"
              required
              value={landingId}
              onChange={(e) => setLandingId(e.target.value)}
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          {/* Top Content Editor */}
          <div className="mb-8 border-r-4 border-red-400 pr-4 mt-4">
            <h3 className="text-xl font-semibold mb-4 text-gray-600">
              Top Content
            </h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Logo URL
                </label>
                <input
                  type="text"
                  value={dataLanding.page.top_content.structure.logo}
                  onChange={(e) =>
                    handleChange(
                      ["page", "top_content", "structure", "logo"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Image URL
                </label>
                <input
                  type="text"
                  value={dataLanding.page.top_content.structure.image}
                  onChange={(e) =>
                    handleChange(
                      ["page", "top_content", "structure", "image"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <input
                  type="text"
                  value={dataLanding.page.top_content.structure.title}
                  onChange={(e) =>
                    handleChange(
                      ["page", "top_content", "structure", "title"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  value={dataLanding.page.top_content.structure.description}
                  onChange={(e) =>
                    handleChange(
                      ["page", "top_content", "structure", "description"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Expired At
                </label>
                <input
                  type="datetime-local"
                  value={
                    dataLanding?.page?.top_content?.structure?.expiredAt
                      ? new Date(
                          dataLanding?.page?.top_content?.structure?.expiredAt
                        )
                          ?.toISOString()
                          ?.slice(0, -1)
                      : undefined
                  }
                  onChange={(e) =>
                    handleChange(
                      ["page", "top_content", "structure", "expiredAt"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Event Type
                </label>
                <input
                  type="text"
                  value={dataLanding.page.top_content.structure.eventType}
                  onChange={(e) =>
                    handleChange(
                      ["page", "top_content", "structure", "eventType"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="flex gap-4">
                <FormCMS formData={formData} setFormData={setFormData} />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Background
                </label>
                <input
                  type="color"
                  value={dataLanding.page.top_content.style.background}
                  onChange={(e) =>
                    handleChange(
                      ["page", "top_content", "style", "background"],
                      e.target.value
                    )
                  }
                  className="mt-1 block px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div className="mb-8 border-r-4 border-green-400 pr-4">
            <h3 className="text-xl font-semibold mb-4 text-gray-600">
              Middle Content
            </h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <input
                  type="text"
                  value={dataLanding.page.mid_content.structure.title}
                  onChange={(e) =>
                    handleChange(
                      ["page", "mid_content", "structure", "title"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <input
                  type="text"
                  value={dataLanding.page.mid_content.structure.description}
                  onChange={(e) =>
                    handleChange(
                      ["page", "mid_content", "structure", "description"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Image URL
                </label>
                <input
                  type="text"
                  value={dataLanding.page.mid_content.structure.image}
                  onChange={(e) =>
                    handleChange(
                      ["page", "mid_content", "structure", "image"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div className="mb-8 border-r-4 border-blue-400 pr-4">
            <h3 className="text-xl font-semibold mb-4 text-gray-600">
              Bottom Content
            </h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <input
                  type="text"
                  value={dataLanding.page.bottom_content.structure.title}
                  onChange={(e) =>
                    handleChange(
                      ["page", "bottom_content", "structure", "title"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  value={dataLanding.page.bottom_content.structure.description}
                  // Make it bigger by default, some rows
                  rows={12}
                  onChange={(e) =>
                    handleChange(
                      ["page", "bottom_content", "structure", "description"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="flex gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Label
                  </label>
                  <input
                    type="text"
                    value={dataLanding.page.bottom_content.structure.link.label}
                    onChange={(e) =>
                      handleChange(
                        [
                          "page",
                          "bottom_content",
                          "structure",
                          "link",
                          "label",
                        ],
                        e.target.value
                      )
                    }
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Link
                  </label>
                  <input
                    type="text"
                    value={dataLanding.page.bottom_content.structure.link.url}
                    onChange={(e) =>
                      handleChange(
                        ["page", "bottom_content", "structure", "link", "url"],
                        e.target.value
                      )
                    }
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Other Sections */}
          {/* Repeat similar blocks for mid_content and bottom_content */}
        </div>

        {/* Preview Panel */}
        <div className="w-3/5 bg-white shadow-md rounded-lg p-6 h-fit">
          <h2 className="text-2xl font-bold mb-4 text-gray-700">Preview</h2>

          <Preview page={dataLanding.page} formInfo={formData} />
        </div>
      </div>
    </div>
  );
}

export default LandingDetails;
