// @ts-nocheck
import {createApi} from "@reduxjs/toolkit/query/react";
import {store} from "store/store";
import {customFetchLandings} from "./customFetchBase";

interface LandingData {
  data: {
    page: {
      top_content: {
        structure: {
          logo: string;
          image: string;
          title: string;
          description: string;
          expiredAt: string;
          eventType: string;
          backgroundExpiredAt: string;
          formInfo: {
            title: string;
            buttonText: string;
            form: any[];
          } | null;
        };
        style: {
          background: string;
          color: string;
          expiredAtBackground: string;
          formColor: string;
        };
      };
      mid_content: {
        structure: {
          title: string;
          description: string;
          image: string;
        };
        style: {
          background: string;
          color: string;
        };
      };
      bottom_content: {
        structure: {
          title: string;
          description: string;
          link: {
            label: string;
            url: string;
          };
        };
        style: {
          background: string;
          color: string;
        };
      };
    };
    environment: string;
  };
}

export const landingsApi = createApi({
  reducerPath: "landingsApi",
  baseQuery: customFetchLandings,
  tagTypes: ["Landings", "Landing"],
  endpoints: (builder) => ({
    getLandings: builder.query<LandingData[], any>({
      query: () => ({
        url: `/administrator/public/landing-pages`,
        method: "GET",
      }),
      providesTags: ["Landings"],
    }),
    getLanding: builder.query({
      query: (id: string) => ({
        url: `/administrator/public/landing-pages/${id}`,
        method: "GET",
      }),
      providesTags: ["Landing"],
    }),
    createLanding: builder.mutation({
      query: (data) => ({
        url: `/administrator/public/add-landing-page`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Landings", "Landing"],
    }),
    updateLanding: builder.mutation({
      query: ({id, data}) => ({
        url: `/administrator/public/landing-pages/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Landings", "Landing"],
    }),
    deleteLanding: builder.mutation({
      query: (id) => ({
        url: `/administrator/public/landing-pages/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Landings", "Landing"],
    }),
    uploadImage: builder.mutation({
      query: (formData) => ({
        url: `/administrator/landing-pages/upload-image`,
        method: "POST",
        body: formData,
        headers: {
          "Auth-Token": store.getState().user.user.user.token,
        },
      }),
    }),
  }),
});

export const {
  useGetLandingsQuery,
  useGetLandingQuery,
  useLazyGetLandingQuery,
  useCreateLandingMutation,
  useUpdateLandingMutation,
  useDeleteLandingMutation,
  useUploadImageMutation,
} = landingsApi;
